//@ts-nocheck
import React from 'react';
// Customizable Area Start
import { Grid, Modal, Button, CircularProgress } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import SelectOptionController from './SelectOptionController.web';
import { galleryLogo, googlePhotoLogo } from './assets';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import Loader from "../../../components/src/Loader.web";
import "./style.css";
import "../../../web/public/index.css"

export class SelectOption extends SelectOptionController {

    render() {

        const uploadingLoader = (
            <Grid item xs={12} container direction="column" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Box position="relative" display="inline-flex">
                    <CircularProgress variant="determinate" value={this.state.progress} />
                    <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                            this.state.progress,
                        )}%`}</Typography>
                    </Box>
                </Box>
                <Typography component="div" color="textSecondary">Uploading...</Typography>
            </Grid>
        )

        return (
            !this.state.min_images ? <Loader loading={true} /> :
                this.state.isLoading ? uploadingLoader :
                    <>
                        <Grid container>
                            <Modal
                                open={this.state.show}
                                onClose={this.handleClose}
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                                className="modal-data"
                            >
                                <Grid container direction="row" justifyContent="center" alignItems="center" style={{ fontFamily: "inherit", position: "absolute", transition: "all 2s" }} >
                                    <Grid item xs={12} lg={6} md={12} sm={12}>

                                        <div className="modal_container">
                                            <span className="closeBtn"
                                                id="close"
                                                onClick={this.handleClose}><CloseIcon /></span>
                                            <h3 style={{ textAlign: "center" }}>Select Photo Source</h3>
                                            <p className="photoSource_para">Add {this.state.min_images} or more (max {this.state.max_images}) photos and let the magic happen</p>
                                            <form style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                                <input
                                                    type="file"
                                                    id="file-input"
                                                    accept="image/*, image/heic"
                                                    style={{ display: "none" }}
                                                    ref={this.imageRef}
                                                    onChange={this.uploadMultipleFiles}
                                                    multiple />
                                                <Button
                                                    type="button"
                                                    id="file-input-btn"
                                                    onClick={this.uploadFiles}
                                                    className="textCapitalize btn-rounded bg-green btn-touch-hover"
                                                    size="large"
                                                    variant="contained"
                                                    color="primary"
                                                    style={{ margin: "auto", fontSize: "16px", fontWeight: "600", width: "90%" }}
                                                >
                                                    <img
                                                        src={galleryLogo}
                                                        style={{
                                                            position: "relative",
                                                            width: "9%",
                                                            height: "auto",
                                                        }} />&nbsp; Mobile Gallery
                                                </Button>
                                            </form>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Modal>
                        </Grid>
                    </>
        )
    }
}
export default withRouter(SelectOption);
// Customizable Area End