//@ts-nocheck
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { RouteComponentProps } from "react-router-dom";
const configJSON = require("./config");
const heic2any = require("heic2any")
import StorageProvider from "../../../framework/src/StorageProvider";
import GoogleAnalytics from "../../../components/src/GoogleAnalytics.web";
// Customizable Area End


export interface Props extends RouteComponentProps<any> {

  navigation: any;
  id: string;
  // Customizable Area Start
  route: any;
  data: any;
  history: any;
  location: any;
  RouteComponentProps: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  subscriptions: any;
  data: any;
  openMenu: boolean;
  nextPageToken: any,
  year: any;
  month: any;
  file: any;
  files: any;
  uploadFile: any;
  xyz: boolean;
  btnActive: boolean;
  show: boolean;
  selectedDropDownMonth: string;
  selectedDropDownYear: number;
  selectedMonth: string,
  selectedYear: any,
  isLoading: boolean,
  progress: number,
  shared_data: any,
  isPopupOpen: boolean,
  min_images: number,
  max_images: number,
  img_resolution: number,
  googlePhotos: any,
  firstDate: any,
  lastDate: any,
  selectedMonth_1: any,
  loggedInWithGoogle: boolean,
  selectedYear_1: any,
  bookPrice: number,
  pagePrice: number,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SelectOptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  public imageRef: React.RefObject<HTMLInputElement>;

  getListCallId: any;
  apiGetImageLimitCallId: any;
  apiGetUserLinkCallId: any;
  apiGetBookPriceCallId: any;
  apiGetPagePriceCallId: any;
  year = (new Date()).getFullYear();
  newYear = this.year - 30
  years = Array.from(new Array(32), (val, index) => index + this.newYear);
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.imageRef = React.createRef();
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      token: "",
      subscriptions: [],
      data: null,
      nextPageToken: null,
      openMenu: false,
      year: null,
      googlePhotos: [],
      month: "",
      file: [],
      files: [],
      loggedInWithGoogle: false,
      uploadFile: [],
      xyz: false,
      btnActive: false,
      show: true,
      selectedDropDownMonth: (new Date()).toLocaleString("default", { month: "long" }),
      selectedDropDownYear: (new Date()).getFullYear(),
      selectedMonth: "",
      selectedYear: null,
      isLoading: false,
      progress: 0,
      shared_data: null,
      isPopupOpen: true,
      min_images: 0,
      max_images: 0,
      img_resolution: 0,
      selectedMonth_1: "",
      selectedYear_1: null,
      firstDate: null,
      lastDate: null,
      bookPrice: 0,
      pagePrice: 0,
    };

    this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this)
    this.uploadFiles = this.uploadFiles.bind(this)
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async convertHEIC(file: any) {
    let blobURL = URL.createObjectURL(file);
    /* istanbul ignore next */
    let blobRes = await fetch(blobURL)
    /* istanbul ignore next */
    let blob = await blobRes.blob()

    // convert to PNG - response is blob
    /* istanbul ignore next */
    let conversionResult = await heic2any({ blob })
    /* istanbul ignore next */
    return conversionResult
  }

  uploadMultipleFiles = async (e: any) => {
    if (this.state.isLoading === false) {
      this.setState({ isLoading: true })
    }
    this.setState({ show: !this.state.show })
    const files = [...e.target.files];
    let bdate: any;
    let adate: any;
    console.log("filesssss", files)
    files.sort((a: any, b: any) => {

      bdate = new Date(b.lastModified);
      adate = new Date(a.lastModified);
      return adate - bdate;
      // }
    });
    this.setState({ xyz: true })

    let newUpload: any = [];
    for (const file of files) {
      let blob;
      let dateArr: any = new Date(file.lastModified).toDateString().split(" ")
      const date: any = dateArr[2] + " " + dateArr[1] + " " + dateArr[3]

      if (file.name.toLowerCase().includes(".heic")) {
        blob = await this.convertHEIC(file)
      } else {
        blob = file
      }

      let img_src = URL.createObjectURL(blob)
      /* istanbul ignore next */
      let resolution: any = await this.getResolution(img_src)
      let fileData = {
        // img: Buffer.from(img as string),
        file_name: file.name,
        img_src: img_src,
        img_type: file.type,
        img_size: file.size,
        isTicked: false,
        image_date: date,
        dpi: resolution.dpi,
        width: resolution.width,
        height: resolution.height
      }
      newUpload = [...newUpload, fileData]
      this.setState({ progress: Math.round(newUpload.length / files.length * 100) })
    }
    /* istanbul ignore next */
    let copyNewUpload = [...newUpload]
    copyNewUpload.sort(function (a: any, b: any) {
      let adpi: any = a.dpi;
      let bdpi: any = b.dpi;
      return bdpi - adpi;
    });

    let MIN_IMAGES = this.state.min_images
    let thresholdFromUploaded = copyNewUpload.length >= MIN_IMAGES ?
      copyNewUpload[MIN_IMAGES - 1].dpi : 0

    let countTicked = 0
    newUpload.map((image: any) => {

      /* istanbul ignore next */
      if (image.dpi >= thresholdFromUploaded && countTicked < MIN_IMAGES) {
        image.isTicked = true
        countTicked += 1
      } else {
        image.isTicked = false
      }
    })
    this.setState({ uploadFile: [...newUpload] })
    /* istanbul ignore next */
    if (this.state.xyz) {
      this.props.history.push({
        pathname: "/GalleryPhoto", state: {
          photos: newUpload,
          month: this.state.selectedDropDownMonth,
          year: this.state.selectedDropDownYear,
          min_images: this.state.min_images,
          max_images: this.state.max_images,
          img_resolution: this.state.img_resolution,
          bookPrice: this.state.bookPrice,
          pagePrice: this.state.pagePrice,
        }
      })
    }
  }

  getResolution = (image: any) => {
    return new Promise((resolve, reject) => {
      let img = new Image()
      /* istanbul ignore next */
      img.onload = () => resolve({ width: img.width, height: img.height, dpi: Math.round((Math.sqrt(img.height ** 2 + img.width ** 2) / 8 + Number.EPSILON) * 100) / 100 })
      /* istanbul ignore next */
      img.onerror = (err) => {
      }
      img.src = image
    })
  }

  uploadFiles(e: any) {
    e.preventDefault()
    if (this.state.uploadFile.length < this.state.min_images) {
      /* istanbul ignore next */
      this.imageRef.current?.click();
    } else {
      alert("stop")
    }
  }

  handleClose = () => {
    this.props.history.push("/");
  }

  apiGetImageLimitRecieve = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.apiGetImageLimitCallId) {
      let data = responseJson?.data[0]
      localStorage.setItem('min_images', data?.min_images)
      this.setState({
        min_images: data.min_images,
        max_images: data.max_images,
        img_resolution: data.img_resolution
      })
    }
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId && responseJson) {
        // apiGetImageLimit Start
        this.apiGetImageLimitRecieve(apiRequestCallId, responseJson)
        // apiGetImageLimit End
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    GoogleAnalytics()
    this.apiGetImageLimit()
    // Customizable Area End
  }
  // Customizable Area Start

  // apiGetImageLimit start
  apiGetImageLimit = () => {
    const header = {
      "Content-Type": configJSON.apiGetImageLimitContentType,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.apiGetImageLimitCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiGetImageLimitEndPoint);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetImageLimitMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // apiGetImageLimit end

  // Customizable Area End
}
